<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 250px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 250px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-row v-show="showAll">
						<a-form-item label="会员昵称" name="userName" class="ui-form__item">
							<a-input v-model:value="formState.userName" placeholder="请输入会员昵称"></a-input>
						</a-form-item>
						
						<a-form-item label="会员手机" name="userPhone" class="ui-form__item">
							<a-input v-model:value="formState.userPhone" placeholder="请输入会员手机"></a-input>
						</a-form-item>
						
						<a-form-item label="卖品券号" name="barcode" class="ui-form__item">
							<a-input v-model:value="formState.barcode" placeholder="请输入卖品券号"></a-input>
						</a-form-item>
						
						<a-form-item class="ui-form__item" label="订单来源" name="source">
							<a-select v-model:value="formState.source" style="width: 190px;" placeholder="请选择订单来源">
								<a-select-option :value="0">全部</a-select-option>
								<a-select-option :value="1">微信小程序</a-select-option>
								<a-select-option :value="2">抖音小程序</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item label="订单编号" name="orderNo" class="ui-form__item">
							<a-input v-model:value="formState.orderNo" placeholder="请输入订单编号"></a-input>
						</a-form-item>
					</a-row>
				</a-row>
				
				<a-row>
					<a-col :span="18">
						<span v-permission="['coupon_snack_sale_export']">
							<exportComponent type="snackConsume" :searchData="searchData"></exportComponent>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table rowKey="orderId" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1700 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'source'">
							<div>
								<a-tag color="green" v-if="record.source === 1">
									微信小程序
								</a-tag>
								<a-tag color="blue" v-else> 抖音小程序 </a-tag>
							</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>会员昵称：{{ record.userName || '-' }}</div>
							<div>会员手机：{{ record.userPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'image'">
							<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'status'">
							{{ transStatus(record.status, 2) }}
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<div v-if="showModal">
		<!-- <detail :id="id" @back="onBack"></detail> -->
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	// import detail from './detail.vue';
	import exportComponent from '@/components/exportReport/exportReport.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getSnackSale } from '@/service/modules/coupon.js';
	export default {
		components: {Icon, exportComponent },
		data() {
			return {
				loading: false,
				showAll: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					couponType: 1,
					// organizationId: 0,
					cinemaId: 0,
					source: 0
				},
				searchData: {},
				id: 0,
				showModal: false,
				modelRef: {},
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '订单号',
					dataIndex: 'orderNo'
				}, {
					title: '订单来源',
					key: 'source',
					width: 120,
					align: 'center'
				}, {
					title: '卖品券号',
					dataIndex: 'barcode'
				}, {
					title: '兑换卖品图片',
					key: 'image',
					width: 120
				}, {
					title: '兑换卖品名称',
					dataIndex: "goodsTitle"
				}, {
					title: '会员信息',
					key: 'userInfo'
				}, {
					title: '交易时间',
					key: 'createTime',
					width: 200
				}, {
					title: '交易状态',
					key: 'status'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getOrganizationList();
			// this.onSearch();
		},
		methods: {
			transStatus(val, type) {
				if (val === 'WAIT_BUYER_PAY') {
					return '待支付';
				}
				if (val === 'SYSTEM_CANCEL') {
					return '系统超时取消';
				}
				if (val === 'CUSTOMER_CANCEL') {
					return '用户自行取消';
				}
				if (val === 'WAIT_SELLER_SEND_GOODS') {
					return '待发货'
				}
				if (val === 'WAIT_CHECK') {
					return '待核销'
				}
				if (val === 'TRADE_FINISH') {
					if (type === 2) {
						return '已核销'
					} else {
						return '订单完成'
					}
				}
				if (val === 'WAIT_BUYER_CONFIRM_GOODS') {
					return '待收货'
				}
				if (val === 'AFTERSALE_FINISH') {
					return '交易关闭'
				}
				if (val === 'CHECKOUT_SUCCESS') {
					return '已下单'
				}
			},
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.source = this.searchData.source ? this.searchData.source : undefined;
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.cardId = this.searchData.cardId ? this.searchData.cardId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getSnackSale({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						ret.data.list.forEach(item => {
							item.imgUrl = JSON.parse(item.goodsInfo).imgUrl
						})
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onDetail(item) {
				this.id = item.orderId;
				this.showModal = true;
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
